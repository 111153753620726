import React from 'react';
import { Box } from '@mui/material';

export function VBox({
  children,
  ...props
}) {
  return (
    <Box {...props} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 1, ...props?.sx }} >
      {children}
    </Box>
  )
}