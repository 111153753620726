import localForage from 'localforage'

//import client, { authorizedCoreFetch, authorizedFetch } from '@/client';

export async function downloadStatistics({ query, labelName, artistName = '', releaseName = '', recordingName = '' }) {
  const { saveAs } = await import('save-as'); 

  //window.open(`${getAPIUrl()}/api/statistics.csv?${querystring}&access_token=${token}`, '_blank')
  //return
  /*authorizedCoreFetch('GET', `/statistics.csv`, {}, { ...query }).then( res => res.blob() )
  .then( blob => {
    //document.write(blob) 
    var file = new File([blob], `Statistics_${labelName}_${artistName}_${releaseName}_${recordingName}_${query.start}-${query.end}.csv`)
    const fileUrl = window.URL.createObjectURL(file); 
    saveAs(blob, `Statistics_${labelName}_${query.start}-${query.end}.csv`)
  });*/
  throw "Not implemented yet";
}

export async function startImportProcess(slug) {
    // @ts-ignore
    const token = await localForage.getItem('token');
    var data = new FormData()
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/importify/api/upload/${slug}/import`,
      {
        body: data,
        method: 'POST', 
        headers: {
          'Authorization': `JWT ${token}`
        }
      }
    )
    if (!response.ok) {
      throw new Error("Failed to start import")
    }
    return await response.json()
}
