import React, { useEffect, useState } from 'react';

import Popover from "@mui/material/Popover";
import { Box, IconButton, Typography } from '@mui/material';

import { CaretLeft, CaretRight } from 'phosphor-react';

export function Tutorial({
  steps,
  open,
  onClose = () => {}
}) {
  const [stepNumber, setStepNumber] = useState(0)
  const [fadeCenter, setFadeCenter] = useState(null)
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    setOpacity(open ? 1 : 0)
  }, [open])

  const handleBackClick = (event) => {
    if (stepNumber - 1 < 0) {
      return
    }
    setStepNumber(stepNumber - 1)
  }

  const handleNextClick = (event) => {
    if ((stepNumber + 1) > steps.length - 1) {
      onClose()
      return
    }
    setStepNumber(stepNumber + 1)
  }

  useEffect(() => {
    const elm = steps[stepNumber].anchorEl.current
    if (elm) {
      console.log(elm)
      const rect = elm.getBoundingClientRect()
      const center = {
        x: rect.left + (rect.width / 2),
        y: rect.top + (rect.height / 2)
      }
      console.log(center)
      setFadeCenter(center)
    }
  }, [stepNumber])
  console.log("fadeCenter", fadeCenter && `calc(50% + ${Math.round(fadeCenter.x)}px) calc(-50% + ${Math.round(fadeCenter.y)}px)`)
  return (
    <>  
      {steps.map((step, i) => {
        if (stepNumber === i) {
          return (
            <Popover
              open={open}
              anchorEl={() => {
                console.log("step.anchorEl", step.anchorEl)
                return step.anchorEl?.current
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              slotProps={{
                backdrop: {
                  onClick: onClose,
                  sx: {
                    opacity,
                    transition: 'opacity 0.5s',
                    '--fade-x': `${Math.floor(fadeCenter?.x)}px`,
                    '--fade-y': `${Math.floor(fadeCenter?.y)}px`,
                    maskImage: fadeCenter && `radial-gradient(circle at ${fadeCenter.x}px ${fadeCenter.y}px, transparent 0%, white 50%)`,
                    background: 'rgba(127, 127, 127, .5)',
                    maskPosition: fadeCenter && ``,
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                    display: open ? 'flex' : 'none'
                  }
                }
              }}
              PaperProps={{
                style: {
                  zIndex: 99,
                  boxShadow: 'none',
                  background: 'linear-gradient(90deg, #00aaff, #0088dd)',
                  color: 'white'
                }
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flex: 1, padding: 1 }}>
                  <Typography variant="h5" color="light">{step.title}</Typography>
                  {step.children}
                </Box>
                <Box sx={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: 1, display: 'flex' }}>
                  <IconButton variant="text" color="light" onClick={handleBackClick}><CaretLeft /></IconButton>
                  <Typography>{(stepNumber + 1)} / {steps.length}</Typography>
                  <IconButton variant="text" color="light" onClick={handleNextClick}><CaretRight /></IconButton>
                </Box>
              </Box>
            </Popover>
          )
        }
      })}
    </>
  )
}