import React, { useEffect, useState, useRef, useContext } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { DashboardLayoutContext } from '../pages/dashboard/layout'

export function ScrollBottomTrigger({
    enabled = true,
    onTrigger = () => {},
    children,
    component=Box,
    ...props
}) {

    const { viewStackRef } = useContext(DashboardLayoutContext)
    const elmRef = useRef(null)
    const [triggered, setTriggered] = useState(false)
    const [isInView, setIsInView] = useState(false)
    const [wasInView, setWasInView] = useState(false)
    const [loading, setLoading] = useState(false)

    const Component = component

    const checkInView = () => {
        if (elmRef.current != null) {
            const rect = elmRef.current.getBoundingClientRect();
            setIsInView(
                rect.top < window.innerHeight && rect.bottom >= 0
            );
        }
    };

    useEffect(() => {
        if (viewStackRef?.current != null) {
            viewStackRef.current.addEventListener("scroll", checkInView);
            return () => {
                if (viewStackRef?.current) {
                    viewStackRef.current.removeEventListener("scroll", checkInView);
                }
            };
        }
    }, [])

    useEffect(() => {
        if (!wasInView && isInView) {
            // Element has come into view
            // Do something with the element ...
            if (!triggered && enabled) {
                setTriggered(true)
                setLoading(true)
                onTrigger().then(() => {
                    setTriggered(false)
                    setLoading(false)
                }).catch(e => {
                    setLoading(false)
                })
            }
        }
    }, [isInView])

    return (
        <Component
            ref={elmRef}
            {...props}
        >
            {loading && <CircularProgress />}
            {children}
        </Component>
    )
}