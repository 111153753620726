
import { useState } from 'react';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

import moment from 'moment';

import { useSearchParam } from '@/hooks';

import { VBox } from '@/components/VBox';
import { HBox } from '@/components/HBox';

import i18n from '@/i18n';

import { Statistics } from '@/components/Statistics';

import { Box, Container, Button, Select, MenuItem, Switch, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { CloudDownload } from '@mui/icons-material';
import { downloadStatistics } from '@/actions/console';
import { LoadingPage } from '@/components/LoadingPage';
import { ErrorView } from '@/components/ErrorView';
//import { GenericList } from '@/components/GenericList';
import { Typography } from '@mui/material';
import { VictoryPie } from 'victory';
import { Case, SwitchCase } from '@/components/Syntax';

const METRICS = ['streams', 'downloads']

const TOP_LIST_QUERY = gql`
  query getTopList(
    $start: Date,
    $end: Date
  ) {
    leads {
      topList(
        start: $start,
        end: $end,
      ) {
        assets(limit: 10) {
          name,
          totals {
            percentage,
            count
          }
        }
      }
    }
  }
`

const QUERY = gql`
  query getStatistics(
    $start: Date,
    $end: Date,
    $labelId: ID,
    $artistIds: [ID],
    $releaseIds: [ID],
    $recordingIds: [ID],
    $serviceIds: [ID]
  ) {
    leads {
      statistics(
        start: $start,
        end: $end,
        labelId: $labelId,
        artistIds: $artistIds,
        releaseIds: $releaseIds,
        recordingIds: $recordingIds,
        serviceIds: $serviceIds
      )
    }
  }
`

export function TopListContainer({
  start: defaultStart,
  end: defaultEnd
}) {
  const [end, setEnd] = useSearchParam('toDate', defaultEnd ?? moment().subtract(1, 'day').format('YYYY-MM-DD'))
  const [start, setStart] = useSearchParam('fromDate', defaultStart ?? moment().subtract(8, 'day').format('YYYY-MM-DD'))

  const [topListViewType, setTopListViewType] = useState('list')

  const { data, loading } = useQuery(
    TOP_LIST_QUERY,
    {
      variables: {
        start,
        end,
        limit: 20
      }
    }
  )

  if (loading) {
    return <LoadingPage />
  }

  const assets = data?.leads?.topList?.assets

  if (!assets) {
    return (
      <ErrorView />
    )
  }

  return (
    <VBox>
      <HBox>
        <Typography sx={{ flex: 1 }} level="h1" component="h1">{i18n.t('top-tracks-on-spotify')}</Typography>
        <Switch
          checked={topListViewType === "pie"}
          onClick={() => {
            setTopListViewType(topListViewType === "list" ? 'pie' : "list")
          }}
        />
      </HBox>
      <SwitchCase value={topListViewType}>
        <Case match="list">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assets?.map((asset, i) => (
                <TableRow key={asset.id}>
                  <TableCell sx={{ textAlign: 'right' }}>{i + 1}</TableCell>
                  <TableCell>{asset.name}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>{asset.totals.count?.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Case>
        <Case match="pie">
          {loading ? (
            <LoadingPage />
          ) : (assets instanceof Array ? (
            <VictoryPie
              colorScale={'qualitative'}
              radius={100}
              style={{
                labels: {
                  fontSize: 5
                }
              }}
              data={
                assets?.map(
                  r => ({
                    x: r.name,
                    y: parseInt(r.totals.count)
                  })
                )
              }
            />
          ) : <ErrorView />)}
        </Case>
      </SwitchCase>
    </VBox>
  )
}

export function StatisticsContainer({
  start: defaultStart,
  end: defaultEnd,
  dataKey: defaultDataKey = 'streams',
  period: defaultPeriod = '7-days',
  recordingIds,
  serviceIds,
  artistIds,
  releaseIds
}) {
  const [end, setEnd] = useSearchParam('toDate', defaultEnd ?? moment().subtract(1, 'day').format('YYYY-MM-DD'))
  const [start, setStart] = useSearchParam('fromDate', defaultStart ?? moment().subtract(8, 'day').format('YYYY-MM-DD'))
  const [dataKey, setDataKey] = useSearchParam('dataKey', defaultDataKey)

  const period = useMemo(() => {
    let ret = "custom"
    if (
      moment(end).format('YYYY-MM-DD') === moment().subtract(1, 'day').format('YYYY-MM-DD') &&
      moment(start).format('YYYY-MM-DD') === moment().subtract(8, 'day').format('YYYY-MM-DD')
    ) {
      ret = "7-days"
    }
    if (
      moment(end).format('YYYY-MM-DD') === moment().subtract(1, 'day').format('YYYY-MM-DD') &&
      moment(start).format('YYYY-MM-DD') === moment().subtract(29, 'day').format('YYYY-MM-DD')
    ) {
      ret = "28-days"
    }
    return ret
  }, [start, end])

  const setPeriod = (value) => {
    switch (value) {
      case "7-days":
        setEnd(moment().subtract(1, 'day').format('YYYY-MM-DD'))
        setStart(moment().subtract(8, 'day').format('YYYY-MM-DD'))
        break;
      case "28-days":
        setEnd(moment().subtract(1, 'day').format('YYYY-MM-DD'))
        setStart(moment().subtract(29, 'day').format('YYYY-MM-DD'))
        break;
    }
  }

  const handleDownloadStatsClick = event => {
    downloadStatistics({
      query: {
        start,
        end,
        recordingIds,
        serviceIds,
        artistIds,
        releaseIds
      }
    })
  }

  const { data, loading } = useQuery(
    QUERY,
    {
      variables: {
        start,
        end,
        recordingIds,
        serviceIds,
        artistIds,
        releaseIds
      }
    }
  )

  const statistics = JSON.parse(data?.leads?.statistics ?? "null")

  return (
    <VBox>
      <Box sx={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <Typography>{start} - {end}</Typography>
        <Select
          sx={{ transitionDelay: `300ms`, minWidth: 200 }}
          placeholder={i18n.t('period')}
          defaultValue={period}
          onChange={(event) => {
            setPeriod(event.target.value)
          }}
        >
          <MenuItem value="7-days">{i18n.t('7-days')}</MenuItem>
          <MenuItem value="28-days">{i18n.t('28-days')}</MenuItem>
        </Select>
        <Switch
          checked={dataKey == "downloads"} 
          onChange={(event) => {
            setDataKey(event.target.checked ? "downloads" : "streams")
          }}
        /> {i18n.t(dataKey)}
        <Button
          startDecorator={<CloudDownload />}
          variant="contained"
          sx={{ display: 'none' }}
          onClick={handleDownloadStatsClick}
        >
          {i18n.t('download-stats')}
        </Button>
      </Box>
      <HBox>
        {loading ?
          <LoadingPage />
         : (data && statistics) ? (
          <Statistics
            key={dataKey}
            dataKey={dataKey}
            start={start}
            end={end}
            data={statistics}
          />
        ) : <ErrorView />}
      </HBox>
    </VBox>
  )
}

export default function StatisticsPage() {
  return (
    <Container>
      <VBox>
        <h1>{i18n.t('statistics')}</h1>
        <StatisticsContainer />
        <TopListContainer />
      </VBox>
    </Container>
  )
}
