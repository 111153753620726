import React from 'react';
import { Box } from '@mui/material';

export function HBox({
  children,
  ...props
}) {
  return (
    <Box {...props} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, ...props?.sx }} >
      {children}
    </Box>
  )
}