import { useSearchParams } from "react-router-dom";

export function useSearchQuery() {
  const [searchParams] = useSearchParams()
  let result = {}
  for (let entry of searchParams.entries()) {
    result[entry[0]] = entry[1]
  }
  return result
}

export function useSearchParam(key, defaultValue = null) {
  const [searchParams, setSearchParams] = useSearchParams()

  let value = defaultValue
  if (searchParams.has(key)) {
    let newValue = searchParams.get(key)
    value = newValue
  }
  if (value === "null") {
    value = null
  }

  const setValue = (value) => {
    if (value) {
      searchParams.set(key, value)
      setSearchParams(searchParams.toString())
      console.log("searchParams", searchParams.toString())
    }
  }

  return [value, setValue]
}
