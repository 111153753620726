import React, { useEffect, useMemo } from 'react';


import moment from 'moment'

import { useWindowSize } from '@uidotdev/usehooks';
import { Typography, useTheme } from '@mui/material';
import { useState } from 'react';

import { VictoryArea, VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip } from 'victory';
import { FlyOut } from './LineSeries/FlyOut';

export function Statistics({
    start,
    end,
    data,
    dataKey
}) {
    var primary = "#000000";
    const { width, height } = useWindowSize()
    
    const maxValue = useMemo(() => {
        let max = 0 
        if (!data?.stats) {
            return max
        }
        for (let value of data.stats) {
            max = Math.max(max, parseFloat(value[dataKey] ?? 0) ?? 0)
        }
        return max
    }, [data?.stats]) 

    const [zoomDomain, setZoomDomain] = useState({ x: [moment(start).toDate(), moment(end).toDate()], y: [0, maxValue] })

    useEffect(() => {
        setZoomDomain({ x: [moment(start).format(), moment(end).toDate()], y: [0, maxValue] })
      }, [start])
    
      useEffect(() => {
        setZoomDomain({ x: [moment(start).toDate(), moment(end).toDate()], y: [0, maxValue] })
      }, [end])
    if (!data?.stats) {
        return <Typography>No stats</Typography>
    }

    return (
        <VictoryChart 
            responsive={true}
            zoomDomain={zoomDomain}
            scale={{ x: "time" }} 
            height={250}
        >
            <VictoryAxis
                fixLabelOverlap={true}
                style={{
                    axisLabel: {
                        fontSize: 10
                    },
                    tickLabels: {
                        fontSize: 6,
                        fontFamily: "DM Sans",
                        fill: 'black'
                    }
                }}
            />
            <VictoryAxis
                fixLabelOverlap={true}
                dependentAxis
                style={{
                    grid: { stroke: 'rgba(127, 127, 127, .3)', strokeWidth: 0.5 },
                    axisLabel: {
                        fontSize: 5
                    },
                    tickLabels: {
                        fontSize: 6,
                        fontFamily: "DM Sans",
                        fill: 'black'
                    }
                }}
            />
            <VictoryArea
                interpolation="linear" 
                data={data.stats} 
                x={(value) => value.date}
                y={(value) => value[dataKey]}
                style={{
                  data: { stroke: primary, fill: `color-mix(in srgb, #000, transparent 50%)`, strokeWidth: 1 }
                }}
                
            />
 
             <VictoryBar
                labels={(o) => o[dataKey] ?? 0} 
                labelComponent={
                    <VictoryTooltip
                        renderInPortal={true}
                        flyoutComponent={<FlyOut />}
                        flyoutStyle={{
                            fill: primary,
                        }}
                        style={{
                        fill: 'white',
                        }} 
                    />
                }
                style={{
                    data: { transform: 'translateX(30pt)', stroke: 'transparent', fill: "transparent" }
                }}
                barWidth={110}
                barRatio={0}
                
                interpolation="linear" 
                data={data.stats} 
                x={(value) => value.date}
                y={(value) => value[dataKey]}
            >
                <VictoryTooltip
                    center={{ x: 225, y: 30 }}
                    pointerOrientation="bottom"
                    flyoutWidth={150}
                    flyoutHeight={50}
                    pointerWidth={150}
                    cornerRadius={0}
                />
            </VictoryBar> 
        </VictoryChart>
    )
}