import React, { useContext, useEffect, useState } from 'react';

import { SortContext, getNodeValue } from '../../contexts/SortContext';

import { TableHeaderCell } from '../TableHeaderCell';
import { InfoOutlined, More, MoreHorizRounded } from '@mui/icons-material';
import { NodeTableRow } from './NodeTableRow';
import { EmptyView } from '../EmptyView';
import { ScrollBottomTrigger } from '../ScrollBottomTrigger';

export function CheckboxTableCell({ slug }) {
  const {
    checkedNodeIds,
    setCheckedNodeIds
  } = useContext(SortContext)

  return (
    <td>
      <input type="checkbox" 
        onClick={(event) => {
          if (event.target.checked) {
            setCheckedNodeIds({
              ...checkedNodeIds,
              [slug]: true
            })
          } else {
            const newCheckedNodeIds = {...checkedNodeIds}
            delete newCheckedNodeIds[slug]
            setCheckedNodeIds(newCheckedNodeIds)
          }
        }}
        checked={slug in checkedNodeIds}
      />
    </td>
  )
}

export function NodeTable({
  nodes,
  renderRowClassNames = () => ``,
  columns,
  canDelete = false,
  onDeleteClicked=() => {},
  renderTableHeaderCells = () => {},
  getValueOfNode = getNodeValue,
  defaultSortByFieldId,
  defaultSortByDirection = 'asc',
  renderTableRow = () => {},
  getRealNode = ({ node }) => node,
  getContextMenuItems = (nodes) => [],
  onNodesChecked = ({ nodes }) => [],
  draggable = false,
  hasSubRows,
  expanderEnabled,
  onTrigger = () => {},
  getSubRows = () => null,
  emptyHeading='Empty',
  emptyIcon=InfoOutlined,
  emptyText='No nodes found',
  onSortByChanged = () => {},
  ...props
}) {
  const [sortBy, setSortBy] = useState(defaultSortByFieldId)
  const [sortByDirection, setSortByDirection] = useState(defaultSortByDirection)
  const [filters, setFilters] = useState({}) 
  const [checkedNodeIds, setCheckedNodeIds] = useState({})
  const [selectedNodeIds, setSelectedNodeIds] = useState({})

  const [sortedNodes, setSortedNodes] = useState(nodes)

  useEffect(() => {
    onNodesChecked({ nodes: sortedNodes.filter(n => n.slug in checkedNodeIds) })
  }, [checkedNodeIds])

  const sortNodes = () => {
    if (sortBy) {
      const sortedNodes = [...nodes]
      sortedNodes.sort((a, b) => {
        const valueA = getNodeValue({ node: a, column: { id: sortBy }})
        const valueB = getNodeValue({ node: b, column: { id: sortBy }})
        if (valueA === null) {
          return 1
        }
        if (valueB === null) {
          return -1
        }
        console.log(valueA, valueB)
        if (valueA > valueB) {
          return sortByDirection === 'asc' ? 1 : -1
        }
        if (valueA < valueB) {
          return sortByDirection === 'asc' ? -1 : 1
        }
        return 0
      })
      setSortedNodes([...sortedNodes])
    } else {
      setSortedNodes(nodes)
    }
    onSortByChanged({
      sortByFieldId: sortBy,
      sortByDirection: sortByDirection
    })
  }

  useEffect(sortNodes, [sortBy])
  useEffect(sortNodes, [sortByDirection])
  useEffect(sortNodes, [nodes])

  return (
    <SortContext.Provider value={{ 
      setSortBy,
      sortBy,
      sortByDirection,
      checkedNodeIds,
      selectedNodeIds,
      setSelectedNodeIds,
      getContextMenuItems,
      setCheckedNodeIds,
      getSubRows,
      getRealNode,
      renderTableRow,
      getValueOfNode,
      setSortByDirection,
      sortedNodes,
      nodes,
      filters,
      setFilters
    }}>
      <>
        <table class="table" cellSpacing={0} tabIndex={0} {...props}>
          <thead>
            <tr>
              {hasSubRows &&
                <th></th>
              }
              <th>
                <input
                  type="checkbox"
                  onClick={(event) => {
                    if (event.target.checked) {
                      const newCheckedNodeIds = {}
                      sortedNodes.forEach(artist => {
                        newCheckedNodeIds[artist.slug] = true
                      })
                      setCheckedNodeIds(newCheckedNodeIds)
                    } else {
                      setCheckedNodeIds({})
                    }
                  }}
                  checked={Object.values(checkedNodeIds)?.length >= nodes.length} 
                />
              </th>
              {columns.map(column => {
                return (
                  <TableHeaderCell style={column.headerStyle} width={column.width} key={column.id} field={column.id} sortable={column.sortable}>{column.name}</TableHeaderCell>
                )
              })}
              <th>
                <MoreHorizRounded />
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedNodes.map(
              node => {
                const selected = node.slug in selectedNodeIds
                const checked = node.slug in checkedNodeIds
              
                return ( 
                  <NodeTableRow
                    level={0}
                    renderRowClassNames={renderRowClassNames}
                    hasSubRows={hasSubRows}
                    rows={getSubRows(node)}
                    node={node}
                    selected={selected}
                    checked={checked}
                    columns={columns}
                    onDeleteClicked={() => {
                      onDeleteClicked(
                        sortedNodes.filter(n => Object.keys(selectedNodeIds).find(slug => n.slug === slug))
                      )
                    }}
                  /> 
                )
              } 
            )}
          </tbody>
          {expanderEnabled && (
            <tfoot>
              <ScrollBottomTrigger
                onTrigger={onTrigger}
                enabled={expanderEnabled}
              >

              </ScrollBottomTrigger>
            </tfoot>
          )}
        </table>
        {nodes?.length < 1 && <EmptyView Icon={emptyIcon} heading={emptyHeading} text={emptyText} />}
      </>
    </SortContext.Provider>
  )
}